exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-authors-js": () => import("./../../../src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-collected-story-collected-story-metadata-story-title-slug-index-js": () => import("./../../../src/pages/collectedStory/{collectedStory.metadata__storyTitleSlug}/index.js" /* webpackChunkName: "component---src-pages-collected-story-collected-story-metadata-story-title-slug-index-js" */),
  "component---src-pages-favourites-js": () => import("./../../../src/pages/favourites.js" /* webpackChunkName: "component---src-pages-favourites-js" */),
  "component---src-pages-hardcore-story-hardcore-story-metadata-story-title-slug-index-js": () => import("./../../../src/pages/hardcoreStory/{hardcoreStory.metadata__storyTitleSlug}/index.js" /* webpackChunkName: "component---src-pages-hardcore-story-hardcore-story-metadata-story-title-slug-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-story-current-story-metadata-story-title-slug-index-js": () => import("./../../../src/pages/story/{currentStory.metadata__storyTitleSlug}/index.js" /* webpackChunkName: "component---src-pages-story-current-story-metadata-story-title-slug-index-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-author-template-js": () => import("./../../../src/templates/author-template.js" /* webpackChunkName: "component---src-templates-author-template-js" */),
  "component---src-templates-collected-story-list-template-js": () => import("./../../../src/templates/collectedStory-list-template.js" /* webpackChunkName: "component---src-templates-collected-story-list-template-js" */),
  "component---src-templates-hardcore-story-list-template-js": () => import("./../../../src/templates/hardcoreStory-list-template.js" /* webpackChunkName: "component---src-templates-hardcore-story-list-template-js" */),
  "component---src-templates-image-story-chapter-template-js": () => import("./../../../src/templates/image-story-chapter-template.js" /* webpackChunkName: "component---src-templates-image-story-chapter-template-js" */),
  "component---src-templates-story-chapter-template-js": () => import("./../../../src/templates/story-chapter-template.js" /* webpackChunkName: "component---src-templates-story-chapter-template-js" */),
  "component---src-templates-story-list-template-js": () => import("./../../../src/templates/story-list-template.js" /* webpackChunkName: "component---src-templates-story-list-template-js" */),
  "component---src-templates-tags-template-js": () => import("./../../../src/templates/tags-template.js" /* webpackChunkName: "component---src-templates-tags-template-js" */)
}

